import { template as template_2fc6bfb1d2184ec7b81e71557f87342a } from "@ember/template-compiler";
const FKControlMenuContainer = template_2fc6bfb1d2184ec7b81e71557f87342a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
