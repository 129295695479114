import { template as template_5bde12d266ee4b55b9a0a6493b1192c0 } from "@ember/template-compiler";
const FKText = template_5bde12d266ee4b55b9a0a6493b1192c0(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
